import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';

const galleryIdDocument = graphql(`
  query galleryId($gallerySlug: String!) {
    galleryId(gallerySlug: $gallerySlug)
  }
`);

export const useGalleryIdBySlug = (gallerySlug: string) => {
  const { data } = useSuspenseQuery(galleryIdDocument, {
    variables: { gallerySlug },
  });

  return data.galleryId;
};

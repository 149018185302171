import { FC, useLayoutEffect } from 'react';

interface RedirectProps {
  url: string;
}

export const Redirect: FC<RedirectProps> = ({ url }) => {
  useLayoutEffect(() => {
    window.location.href = url;
  }, [url]);

  return <></>;
};
